import React, { useState } from 'react'
import { Container, Col, Row } from 'react-bootstrap'
import { Button, Spin, Alert, Table } from 'antd'
import Navigation from './Navigation'
import Footer from './Footer'
import './Content.css'
import { useQuery, useMutation } from '@apollo/client'
import { useLocation } from 'react-router'
import Constants, { currentPath, roles } from '../Constants'
import { amountFormat, dateToPST } from '../Common/HelperFunctions'

const Fund = (props) => {
	const userRole = localStorage.getItem(Constants.userRole)
	const [alertMessage, setAlertMessage] = useState()

	const [tableData, setTableData] = useState([])
	const location = useLocation()

	const resetState = () => {
		setTimeout(() => {
			setAlertMessage()
		}, 3000)
	}

	const path = location.pathname

	const { loading, refetch } = useQuery(props.GET_APPROVED_PAYMENTS, {
		onCompleted(data) {
			const _IndividualPayArr =
				path == currentPath.fundOrder ? data.getAllOrder : data.getAllFundReport
			setTableData(_IndividualPayArr)
			console.log(_IndividualPayArr)
		},
		fetchPolicy: 'network-only',
	})

	const [fund, { loading: fundingLoading }] = useMutation(props.FUND, {
		onCompleted() {
			const orderOrReport = path == currentPath.fundOrder ? 'Order' : 'Report'
			setAlertMessage(() => {
				window.scrollTo({ top: 0, left: 0, behaviour: 'smooth' })
				return (
					<Alert
						type="success"
						message={`${orderOrReport} funded successfully`}
					></Alert>
				)
			})
			resetState()
		},
	})

	const fundOrderHandler = async (id) => {
		try {
			const res = await fund({
				variables: {
					id,
				},
			})

			if (res) {
				refetch()
			}
		} catch (err) {
			setAlertMessage(() => {
				window.scrollTo({ top: 0, left: 0, behaviour: 'smooth' })
				return <Alert type="error" message={err.message}></Alert>
			})

			resetState()
		}
	}

	const columns = [
		path == currentPath.fundOrder
			? {
					title: 'Order Id',
					dataIndex: 'orderId',
					key: 'orderId',
					fixed: 'left',
			  }
			: {
					title: 'Report Id',
					dataIndex: 'reportId',
					key: 'reportId',
					fixed: 'left',
			  },
		{
			title: 'Date Created',
			dataIndex: 'createdAt',
			key: 'createdAt',
			render: (_, record) => {
				const formattedAmount = dateToPST(record.createdAt)
				return formattedAmount
			},
		},
		path == currentPath.fundOrder
			? {
					title: 'Order Total',
					dataIndex: 'reportTotal',
					key: 'reportTotal',
					render: (_, record) => {
						const formattedAmount = amountFormat(record.reportTotal)
						return ` ${formattedAmount}`
					},
			  }
			: {
					title: 'Report Total',
					dataIndex: 'reportTotal',
					key: 'reportTotal',
					render: (_, record) => {
						const formattedAmount = amountFormat(record.reportTotal)
						return ` ${formattedAmount}`
					},
			  },
		{
			title: 'Download Link',
			dataIndex: 's3SignedFileUrl',
			key: 's3SignedFileUrl',
			render: (_, record) => {
				return (
					<>
						{userRole !== roles.ACCOUNTING || record.funded ? (
							<a href={`${record.s3SignedFileUrl}`} download>
								Download
							</a>
						) : (
							''
						)}
					</>
				)
			},
		},
		{
			title: 'Funded Date',
			dataIndex: 'fundedAt',
			key: 'fundedAt',
			render: (_, record) => {
				if (record.fundedAt) {
					return dateToPST(record.fundedAt)
				}
				return ''
			},
		},
		{
			title: 'Action',
			render: (_, record, prevRecord) => {
				if (userRole !== roles.ACCOUNTING) {
					if (record.funded == true) {
						return (
							<>
								<Button disabled type="link">
									Funded
								</Button>
							</>
						)
					} else {
						return (
							<>
								<Button type="link" onClick={() => fundOrderHandler(record.id)}>
									Fund {path == currentPath.fundOrder ? 'Order' : 'Report'}
								</Button>
							</>
						)
					}
				}
			},
		},
	]

	return (
		<>
			<section className="vh-fill">
				<Navigation />

				<Container className="my-5 py-4">
					<Row>
						<Col>
							<h4>
								{path == currentPath.fundOrder
									? 'Invoice Order'
									: 'Fund Report'}
							</h4>
						</Col>
					</Row>
					<Row>
						<Col className="alertmessage m-1">{alertMessage}</Col>
					</Row>
					<Row className="mb-2">
						{loading || fundingLoading ? (
							<Spin
								className="mt-5 d-flex justify-content-center"
								size="large"
							></Spin>
						) : (
							<Table
								dataSource={tableData}
								columns={columns}
								scroll={{ x: 1300, y: 500 }}
							/>
						)}
					</Row>
				</Container>
			</section>
			<Footer />
		</>
	)
}

export default Fund
